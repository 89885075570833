import React from "react"
import logo from "./1.Primary Logo.png"
import {
  Avatar,
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material"
import { EmailOutlined, Instagram, LocalPhone } from "@mui/icons-material"

const StaticPage = () => {
  const { palette } = useTheme()

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box sx={{ mt: 2 }}>
        <img width="350px" src={logo} alt="logo" />
      </Box>
      <Box
        sx={{
          borderRadius: "5px",
          mt: 10,
          backgroundColor: palette.primary.main,
          p: 1,
          color: palette.secondary.main,
          textAlign: "start",
        }}
      >
        <Grid rowGap={1} container >
          <Grid xs={12} md={6}>
            <Typography variant="caption">Mathias Jansson</Typography>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack alignItems="center" gap={1} direction="row">
              <Avatar
                sx={{ backgroundColor: "#9ABECE", height: 30, width: 30 }}
              >
                <LocalPhone color="primary" fontSize="15px" />
              </Avatar>

              <Typography variant="caption"> 070 - 692 72 84</Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack alignItems="center" gap={1} direction="row">
              <Avatar
                sx={{ backgroundColor: "#9ABECE", height: 30, width: 30 }}
              >
                <EmailOutlined color="primary" fontSize="15px" />
              </Avatar>

              <Typography
                sx={{ textDecoration: "none", color: palette.secondary.main }}
                target="_blank"
                component="a"
                href="mailto:arefishingguides@gmail.com"
                variant="caption"
              >
                arefishingguides@gmail.com
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack alignItems="center" gap={1} direction="row">
              <Avatar
                sx={{ backgroundColor: "#9ABECE", height: 30, width: 30 }}
              >
                <Instagram color="primary" fontSize="15px" />
              </Avatar>

              <Typography variant="caption"> arefishingguides</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default StaticPage
