import "./App.css"
import StaticPage from "./StaticPage"
import { ThemeProvider } from "@mui/material"
import {theme} from './theme.js'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <StaticPage />
        {/* <Page /> */}
      </ThemeProvider>
    </div>
  )
}

export default App
